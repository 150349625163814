exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-envia-correo-jsx": () => import("./../../../src/pages/envia-correo.jsx" /* webpackChunkName: "component---src-pages-envia-correo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infraestructura-jsx": () => import("./../../../src/pages/infraestructura.jsx" /* webpackChunkName: "component---src-pages-infraestructura-jsx" */),
  "component---src-pages-inteligencia-de-amenazas-jsx": () => import("./../../../src/pages/inteligencia-de-amenazas.jsx" /* webpackChunkName: "component---src-pages-inteligencia-de-amenazas-jsx" */),
  "component---src-pages-seguridad-aplicativa-jsx": () => import("./../../../src/pages/seguridad-aplicativa.jsx" /* webpackChunkName: "component---src-pages-seguridad-aplicativa-jsx" */),
  "component---src-pages-servicios-normativos-y-de-gobierno-jsx": () => import("./../../../src/pages/servicios-normativos-y-de-gobierno.jsx" /* webpackChunkName: "component---src-pages-servicios-normativos-y-de-gobierno-jsx" */)
}

